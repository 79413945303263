$font-primary: "Open Sans", "-apple-system", "BlinkMacSystemFont", "Segoe UI",
  "Roboto", "Helvetica", "Arial", "sans-serif", "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol";
$font-secondary: "Josefin Sans", $font-primary;
$font-tertiary: "Helvetica", "Arial", "droid serif", "sans-serif";
$color-blue: #4fb2ce;
$color-green: #2ecc71;
$color-greenBlue: #52a59c;
$color-greenDark: #15b358;
$color-greenDarker: #278e83;
$color-grey999: #999;
$color-grey: #ccc;
$color-greyAlt: #f7f7f7;
$color-greyBlue: #d7e4ed;
$color-greyDark: #333;
$color-greyHeader: #5b5d5d;
$color-greyShade: #6b6b6b;
$color-greyText: #6c7575;
$color-orange: #cc6519;
$color-pink: #ce157a;
$color-red: #c71825;
$color-redAlt: #f5003d;
$color-redLight: #e21b2a;
$color-redShade: #8b111a;
$color-white: #fff;
