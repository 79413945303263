@import "./theme";

@mixin button-styles {
  display: block;
  padding: 0.5rem 1.5rem;
  font-size: 1.25rem;
  font-weight: 300;
  font-family: $font-secondary;
  line-height: 1.3333;
  border-radius: 0.3rem;
  outline: 0 !important;
  user-select: none;
  cursor: pointer;
  color: $color-white;
  white-space: normal;
  background: $color-red;
  border: 1px solid transparent;
  width: auto;
  margin: 10px 0 20px;

  &.button--center {
    margin: 10px auto 20px;
  }

  &.button--full-width {
    width: 100%;
  }

  &:hover {
    background: $color-redLight;
    color: $color-white;
  }

  &:disabled {
    cursor: not-allowed;
    border-color: $color-grey;
    background: transparent;
    color: $color-grey;
    pointer-events: none;

    :hover {
      background: transparent;
      color: $color-grey;
    }
  }
}

.account-form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}

.account-form-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border: 1px solid $color-grey;
  background: $color-white;
  width: 500px;
  max-width: 95%;
  margin: auto;
  text-align: center;
}

.account-form-main {
  width: 100%;
  padding: 1em;
}

.errorlist,
.account-form-messages {
  padding: 1em;
  padding-left: 1em;
  margin: 1em 0;
  clear: both;
  color: #262626;
  background-color: #faecd5;
}

.account-form-messages {
  background-color: #e6f2f8;
}

.errorlist,
.account-form-messages ul {
  list-style: none;
}

.account-form {
  label {
    display: block;
    font-family: $font-primary;
    font-weight: 600;
    text-align: left;
  }

  input {
    appearance: none;
    background: $color-white;
    border: 1px solid $color-grey;
    border-radius: 0.3rem;
    color: $color-greyText;
    display: block;
    font-family: $font-primary;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.3333;
    margin: 10px 0 20px;
    outline: 0 !important;
    padding: 0.75rem 1.25rem;
    text-decoration: none;
    width: 100%;

    &:disabled {
      background: $color-grey !important;
      border-color: $color-grey !important;
      color: $color-greyText !important;
      cursor: not-allowed;

      &:hover {
        background: inherit;
        border-color: inherit;
        color: inherit;
      }
    }

    &[type="submit"] {
      @include button-styles;
    }
  }

  button {
    @include button-styles;
  }

  header {
    margin-bottom: 1em;

    p {
      padding: 0.5em 0;
    }
  }

  footer {
    font-family: $font-secondary;
  }

  hr {
    border: 1px solid $color-grey;
  }
}
